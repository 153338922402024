define('youtubePlayer', ['youtubeApi'], function() {

  

  var properties;
  var youTubePlayer;
  var theatrePlayer;

  properties = {
    theatrePlayerId: 'widget-youtube__gallery__player',
    theatreInfoId: 'widget-youtube__gallery__player__info',
    cssClasses: {
      trigger: 'js-youtube-video',
      thumbnail: 'js-youtube-video-thumb',
      videoItem: 'js-youtube-video__item',
      mask: 'js-youtube-video__mask',
      loaded: 'widget-youtube__watermark__slide__image--loaded'
    },
    attributes: { /* set on each youtube widget html element */
      startTime: 'data-start',
      endTime: 'data-end',
      loop: 'data-loop',
      width: 'data-width',
      height: 'data-height',
      display: 'data-display',
      autoplay: 'data-autoplay',
      controls: 'data-controls',
      mute: 'data-mute',
      rel: 'data-rel',
      showinfo: 'data-showinfo',
      collectionDisplay: 'data-collectionDisplay',
      title: 'data-title',
      subtitle: 'data-subtitle',
      startDelay: 'data-startDelay'
    },
    collectionDisplays: { /* set by the youtube widget set */
      theatre: 'gallery', /* plays a video by action from a thumbnail */
      list: 'list', /* prints the videos as a grid / list */
      carousel: 'carousel' /* prints the videos in a carousel and keeps the individual widget settings */
    }
  };

  youTubePlayer = function($containerElement, done) {

    this.done = done;
    this.$elements = {
      container: $containerElement,
      video: $containerElement.querySelectorAll('.' + properties.cssClasses.videoItem),
      thumbnail: $containerElement.querySelectorAll('.' + properties.cssClasses.thumbnail),
      theatreInfo: document.getElementById(properties.theatreInfoId),
      mask: $containerElement.querySelectorAll('.' + properties.cssClasses.mask)
    };

    if (this.asVideo()) {
      this.triggerType = 'video';
      this.setElement();
      this.setAttributes();
      this.getPlayer();
    }

    if (this.asThumbnail()) {
      this.triggerType = 'thumbnail';
      this.setElement();
      this.setThumbnailParameters();
      this.thumbnailListen();
      this.returnPlayer();
    }
  };

  youTubePlayer.prototype = {
    setElement: function() {
      this.$elements[this.triggerType] = this.$elements[this.triggerType][0];
    },
    asVideo: function() {
      return this.$elements.video && this.$elements.video.length;
    },
    asThumbnail: function() {
      return this.$elements.thumbnail && this.$elements.thumbnail.length;
    },
    getAttribute: function(attribute) {
      return this.$elements[this.triggerType].getAttribute(properties.attributes[attribute]);
    },
    setAttributes: function() {
      this.times = {
        start: this.startSeconds(),
        end: this.endSeconds()
      };

      this.scale = {
        width: this.getAttribute('width'),
        height: this.getAttribute('height')
      };

      this.controls = this.getAttribute('controls');
      this.controls = this.controls ? this.controls : 0;
      this.showinfo = this.getAttribute('showinfo');
      this.showinfo = this.showinfo ? this.showinfo : 0;
      this.autoplay = parseInt(this.getAttribute('autoplay'));
      this.autoplay = this.canAutoplayOnDevice() ? this.autoplay : 0;
      this.collectionDisplay = this.getAttribute('collectionDisplay');
      this.loop = this.getAttribute('loop');
      this.isMute = parseInt(this.getAttribute('mute'));
      this.startDelay = parseInt(this.getAttribute('startDelay'));
      this.startDelay = this.startDelay ? this.startDelay : 500;
    },
    startSeconds: function() {
      return parseInt(this.getAttribute('startTime'));
    },
    endSeconds: function() {
      return parseInt(this.getAttribute('endTime'));
    },
    returnPlayer: function() {
      return true;
    },
    isIOSHandheld: function() {
      return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    },
    canAutoplayOnDevice: function() {
      return !this.isIOSHandheld();
    },
    mute: function() {
      this.video.mute();
    },
    unMute: function() {
      this.video.unMute();
    },
    start: function() {
      this.video.seekTo(this.times.start);
      this.listen();
    },
    stop: function() {
      this.video.stopVideo();
    },
    pause: function() {
      this.video.pauseVideo();
    },
    hasEnded: function() {
      return this.video.getCurrentTime() >= this.times.end;
    },
    loaded: function() {
      var show = function() {
        this.$elements.mask[0].className = this.$elements.mask[0].className += ' ' + properties.cssClasses.loaded;
      }.bind(this);

      if (this.$elements.mask.length) {
        setTimeout(show, this.startDelay);
      }
    },
    listen: function() {
      clearInterval(this.listenforEnd);

      var action = function() {
        if (this.hasEnded()) {
          this.loop ? this.start() : this.stop();
        }
      }.bind(this);

      this.listenforEnd = setInterval(action, 100);
    },
    setVideoTitle: function(title) {
      if (!this.$elements.theatreInfo) {
        return;
      }
      this.$elements.theatreInfo.innerHTML = '<h1>' + this.getAttribute('title') + '</h1>';
      this.$elements.theatreInfo.innerHTML += '<h3> ' + this.getAttribute('subtitle') + '</h3>';
    },
    shouldAutoPlay: function() {
      return parseInt(this.getAttribute('autoplay'));
    },
    loadVideoByDemand: function() {
      var onPlayerReady = function() {
        this.setVideoTitle();
        if (this.canAutoplayOnDevice() && this.shouldAutoPlay()) {
          theatrePlayer.playVideo();
        }
      }.bind(this);

      var thumbnailParameters = JSON.parse(JSON.stringify(this.thumbnailParameters));

      thumbnailParameters.events = {
        'onReady': onPlayerReady
      };

      if (!this.shouldAutoPlay()) {
        thumbnailParameters.playerVars.autoplay = 0;
      }
      theatrePlayer = new window.YT.Player(properties.theatrePlayerId, thumbnailParameters);
    },
    setThumbnailParameters: function() {
      var _this = this;
      this.thumbnailParameters = {
        playerVars: {
          controls: 1,
          autoplay: 1,
          autohide: 1,
          start: this.startSeconds(),
          end: this.endSeconds(),
          wmode: 'opaque',
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          modestbranding: 1
        },
        videoId: _this.$elements.thumbnail.id
      };
    },
    loadVideoById: function() {

      theatrePlayer.loadVideoById({
        'videoId': this.thumbnailParameters.videoId,
        'startSeconds': this.startSeconds(),
        'endSeconds': this.endSeconds()
      });
      this.setVideoTitle();

    },
    thumbnailListen: function() {
      var thumbnailAction = function() {
        if (theatrePlayer) {
          this.loadVideoById();
        } else {
          this.loadVideoByDemand();
        }
      }.bind(this);

      this.$elements.thumbnail.addEventListener('click', thumbnailAction);
    },
    getDisplayType: function() {
      return this.getAttribute('display');
    },
    getCollectionDisplay: function() {
      return this.getAttribute('collectionDisplay');
    },
    isTheatre: function() {
      return this.getCollectionDisplay() === properties.collectionDisplays.theatre;
    },
    isList: function() {
      return this.getCollectionDisplay() === properties.collectionDisplays.list;
    },
    isCarousel: function() {
      return this.getCollectionDisplay() === properties.collectionDisplays.carousel;
    },
    getPlayer: function() {
      var onPlayerReady;
      var _this = this;
      var playerObject;

      onPlayerReady = function() {
        if (this.isMute) {
          this.mute();
        }

        this.returnPlayer();
        this.loaded();

      }.bind(this);

      playerObject = {
        playerVars: {
          autoplay: this.autoplay,
          controls: _this.controls,
          autohide: 1,
          wmode: 'opaque',
          rel: 0,
          showinfo: _this.showinfo ? _this.showinfo : 0,
          iv_load_policy: 3,
          modestbranding: 1
        },
        videoId: _this.$elements.video.id,
        events: {
          'onReady': onPlayerReady
        },
        width: _this.scale.width,
        height: _this.scale.height
      };
      this.video = new window.YT.Player(this.$elements.video.id, playerObject);
    }
  };

  return youTubePlayer;
});
